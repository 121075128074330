import CheckIcon from '@mui/icons-material/Check';
import {
  Avatar,
  Container,
  CssBaseline,
  Paper,
  Typography
} from '@mui/material';

function Success (props) {
  return (
    <>
      <CssBaseline />
      <Container
        data-cy="component-email-link"
        component="main"
        maxWidth="sm"
        sx={{ mb: 4 }}>
        <Paper
          variant="elevation"
          elevation={0}
          sx={{
            my: {
              xs: 3,
              md: 1
            },
            p: {
              xs: 2,
              md: 1
            },
            display:       'flex',
            flexDirection: 'column'
          }}>
          {props.header &&
          <Typography
            component="h1"
            variant="h4"
            align="center">
            {props.header}
            <hr />
          </Typography>
          }
          <>
            <Avatar sx={{
              display:   'flex',
              alignSelf: 'center',
              bgcolor:   'green',
              width:     70,
              height:    70
            }}>
              <CheckIcon fontSize="large"/>
            </Avatar>
            <Typography
              sx={{
                marginTop:    2,
                marginBottom: 2
              }}
              component="p"
              variant="p"
              align="center">
              {props.message || 'Success'}
            </Typography>
          </>
        </Paper>
      </Container>
    </>
  );
}

export { Success as default, Success };

import Head from 'next/head';
import React, { useState, useMemo, useEffect, useReducer } from 'react';
import Success  from '../../../components/success';
import { AuthOptions } from '../api/auth/[...nextauth]';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useTheme } from '@mui/material/styles';
import { unstable_getServerSession } from 'next-auth/next';
import TextField from '../../../components/textField';
import LockResetIcon from '@mui/icons-material/LockReset';
import CircularProgress from '@mui/material/CircularProgress';

const EmailLinkSent = () => {
  return <Success
    messages="A link to set your password has been sent to your email"
    header={'Email Link Sent'}
  />;
};

const DynamicField = (props) => {
  let {
    label,
    placeholder,
    type,
    fields,
    isAuthenticating,
    setFields,
    autoComplete,
    validation,
    id
  } = props;

  return <TextField
    data-cy={`${id}-field`}
    margin="normal"
    disabled={isAuthenticating}
    required
    fullWidth
    value={fields[id]?.value || ''}
    name={label}
    error={fields[id]?.error}
    label={label}
    type={type}
    autoComplete={autoComplete || 'off'}
    mask={type === 'password'}
    onInput={({ target: { value } }) => setFields({
      key:        id,
      validation: validation,
      value
    })}
    id={id}
    placeholder={placeholder}
  />;
};

function setFieldsReducer (prevState, args) {
  // Allows setFields to be reset, i.e. setFields({})
  if (Object.keys(args).length === 0) {
    return {};
  }
  let { key, value, masked, error, validation } = args;

  const newState = { ...prevState };
  const fieldState = newState[key] || {};

  fieldState.error = error;

  if (value !== undefined) {
    if (validation) {
      [ fieldState.value ] = validation(value);
    } else {
      fieldState.value = value;
    }
  }

  if (masked !== undefined) {
    fieldState.masked = masked;
  }

  newState[key] = fieldState;

  return newState;
}

export default function Login (props) {
  const router = useRouter();
  const theme = useTheme();
  const [ generalError, setGeneralError ] = useState(false);
  const [ message, setMessage ] = useState(false);

  const [ isAuthenticating, setIsAuthenticating ] = useState(false);
  const [ linkSent, setLinkSent ] = useState(false);
  const [ fields, setFields ] = useReducer(setFieldsReducer, []);

  const credentialProviders = useMemo(() => JSON.parse(props.credentialProvider), [ props ]);
  const [ radioButtonValue, setRadioButtonValue ] = useState(credentialProviders[0]?.options?.id || '');

  const selectedCredentialProvider = useMemo(() => credentialProviders.length <= 1 ? credentialProviders[0]?.options : credentialProviders.filter(credential => credential.options.id === radioButtonValue.toLowerCase())[0].options, [ credentialProviders, radioButtonValue ]);
  const radioButtons = credentialProviders.map(providerOption => {
    return <FormControlLabel
      key={providerOption.options.id}
      value={providerOption.options.id}
      control={<Radio />}
      label={providerOption.options.name}
    />;
  });

  const credentials = selectedCredentialProvider?.credentials || {};
  const loginFields = [];
  for (const key in credentials) {
    if (Object.prototype.hasOwnProperty.call(credentials, key)) {
      const provider = credentials[key];

      loginFields.push(
        <DynamicField
          id={key}
          setFields={setFields}
          fields={fields}
          isAuthenticating={isAuthenticating}
          // eslint-disable-next-line no-eval
          validation={eval(provider.validation)}
          key={provider.label}
          name={provider.name}
          autoComplete={provider.autoComplete}
          label={provider.label}
          startIcon={<></>}
          placeholder={provider.placeholder}
          type={provider.type}
        />
      );
    }
  }
  useEffect(() => setGeneralError(false), [ router.query.slug, radioButtonValue ]);
  useEffect(() => setFields({}), [ radioButtonValue, router.query.slug ]);

  const reset = async (event) => {
    event.preventDefault();

    setIsAuthenticating(true);
    if (!fields?.email?.value) {
      setGeneralError(true);
      setIsAuthenticating(false);
      return;
    }

    const resetPWBody = await (await fetch('/api/pw-recovery', {
      method: 'PATCH',
      body:   JSON.stringify({ email: fields.email.value })
    })).json();

    if (!resetPWBody.success) {
      setGeneralError(true);
      setMessage(resetPWBody?.message);
      setIsAuthenticating(false);
      return;
    }

    setIsAuthenticating(false);
    return setLinkSent(true);
  };

  const handleSubmit = async (event) => {
    setIsAuthenticating(true);
    event.preventDefault();
    let error = false;

    const userInput = {};

    for (const key in credentials) {
      if (Object.prototype.hasOwnProperty.call(credentials, key)) {
        if (!fields[key]?.value) {
          setFields({
            key:   key,
            error: true
          });
          error = true;
          continue;
        }
        userInput[key] = fields[key].value;
      }
    }

    if (error) {
      setIsAuthenticating(false);
      return;
    }

    let res = await signIn(radioButtonValue, {
      ...userInput,
      redirect: false
    });

    if (res?.error) {
      setGeneralError(true);
      setMessage(res?.error);
      setIsAuthenticating(false);
      return;
    }
    if (res?.ok && !res?.error) {
      if (router.query.slug === 'email') {
        return setLinkSent(true);
      }

      if (selectedCredentialProvider?.callbackUrl) {
        return router.replace(selectedCredentialProvider?.callbackUrl);
      }
      router.push('/');
    }
    setGeneralError(false);
    setIsAuthenticating(false);
  };

  if (linkSent) {
    return <EmailLinkSent />;
  }

  return (
    <Container
      className="component-login"
      data-cy="page-login"
      component="main"
      maxWidth="xs">
      <Head><title>AE Broker - Login</title></Head>
      <CssBaseline />
      <Box
        sx={{
          marginTop:     10,
          display:       'flex',
          flexDirection: 'column',
          alignItems:    'center'
        }}>
        <Typography
          id="login-header"
          variant="h4">
          {router.query.slug === 'reset' ? 'Password Recovery' : 'Sign into AE Broker'}
        </Typography>
        {
          !credentialProviders.length &&
            <Box  sx={{ mt: 1 }}>
              <Button
                data-cy="button-login-email"
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2
                }}
                onClick={() => {
                  router.replace('/login/existing');
                  setRadioButtonValue('existing');
                }}>
                login
              </Button>
            </Box>
        }
        {
          /* Login Form Fields */
          router.query.slug !== 'multi' && !!credentialProviders.length &&
          <>
            <Typography variant="h6">
              {selectedCredentialProvider.label}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                value={radioButtonValue}
                onChange={({ target: { value } }) => setRadioButtonValue(value)}>
                {credentialProviders.length > 1 && radioButtons}
              </RadioGroup>
            </FormControl>
            <Typography
              data-cy="general-error-message"
              component="p"
              variant="string"
              sx={{ color: theme.palette.error.main }}>
              {generalError && message}
            </Typography>
            <Box
              component="form"
              onSubmit={router.query.slug === 'reset' ? reset : handleSubmit}
              noValidate
              sx={{ mt: 1 }}>
              {loginFields}
              {
                selectedCredentialProvider.helperText &&
                <span className="helper-text">
                  {selectedCredentialProvider.helperText?.text} &nbsp;
                  <Link
                    id="login-helper-text"
                    onClick={() => {
                      setRadioButtonValue(selectedCredentialProvider.helperText?.provider);
                      router.push(selectedCredentialProvider.helperText?.route);
                    }}
                    sx={{
                      opacity:       isAuthenticating ? 0.5 : 1,
                      cursor:        'pointer',
                      pointerEvents: isAuthenticating ? 'none' : 'auto'
                    }}>
                    <b>{selectedCredentialProvider.helperText?.hyperlink}</b>
                  </Link>
                </span>
              }
              <Button
                data-cy="login-button"
                type="submit"
                fullWidth
                disabled={isAuthenticating}
                variant="contained"
                startIcon={isAuthenticating && <CircularProgress
                  size={15}
                  sx={{ color: '#757575' }}
                />}
                sx={{
                  mt: 3,
                  mb: 2
                }}>
                {selectedCredentialProvider.loginButtonText}
              </Button>
            </Box>
            {
              ![ 'reset', 'employee' ].includes(router.query.slug) &&
              <>
                <Box style={{
                  margin:     '1em',
                  display:    'flex',
                  alignItems: 'center',
                  flexWrap:   'wrap',
                  color:      theme.palette.error.main,
                  opacity:    isAuthenticating ? 0.5 : 1
                }}>
                  <LockResetIcon sx={{ marginRight: '0.1em' }}  />
                  <Typography
                    sx={{
                      cursor:        'pointer',
                      pointerEvents: isAuthenticating ? 'none' : 'auto',
                      display:       'inline',
                      textAlign:     'center'
                    }}
                    onClick={() => router.replace('/login/reset')}
                    component="p"
                    variant="string">
                    Forgot Password
                  </Typography>
                </Box>
              </>
            }
          </>
        }
      </Box>
    </Container>
  );
}

export async function getServerSideProps (context) {
  const session = await unstable_getServerSession(context.req, context.res, AuthOptions);

  if (session) {
    return {
      redirect: {
        destination: session?.user?.role !== 'employee' ?  '/auth/shared' : '/auth/employee',
        permanent:   false
      }
    };
  }

  const filterKeys = {
    employee: [ 'ssn', 'uid' ],
    email:    [ 'email' ],
    reset:    [ 'reset' ],
    existing: [ 'existing' ]
  }[context.query.slug] || [];

  const filteredProviders = AuthOptions.providers.filter(provider => filterKeys.includes(provider.options.id));

  return { props: { credentialProvider: JSON.stringify(filteredProviders || {}) } };
}
